import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/service.css";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="first_three_box" style={{ paddingTop: "3%" }}>
          <div className="first_three_box_grid_about">
            <div className="about__pp">
              <img
                alt=""
                src="https://static.wixstatic.com/media/149b90_bc24092be5034353bebaa7329e1951c9~mv2.jpg/v1/crop/x_236,y_0,w_479,h_607/fill/w_359,h_445,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_7706_JPG.jpg"
              />
              <p>
                ＜保有資格＞
                <br />
                ・インテリアコーディネーター
                <br />
                ・ライフスタイルプランナー
                <br />
                ​・２級色彩コーディネーター
                <br />
                ・測量士補
                <br />
                ・アロマ​テラピー検定２級
                <br />
                ・TOEIC 850/TOEFL 91　等
              </p>
              <br />
              <p>
                ＜使用可能ソフト＞
                <br />
                ・VectorWorks
                <br />
                ・AutoCAD
                <br />
                ・Adobe
                <br />
                ・Microsoft office　等
              </p>
            </div>

            <div className="about__pp">
              <p>
                <strong>宿谷 悠美（しゅくや　ゆみ）</strong>
              </p>
              <p>
                ２０１５年 Umyco Design　として独立。
                <br />
                以来、住宅から商業施設まで幅広い範囲を対象にフリーランスのインテリアデザイナーとして活動中。
              </p>
              <p>
                大学で建築を勉強後、IKEAで販売の仕事をしながら町田ひろ子アカデミーのインテリアコーディネーター全日制コースを卒業。卒業時にインテリアコーディネート最優秀賞獲得。
                <br />
                その後Arflex
                Japanにてハイエンドのお客様相手にショールームで販売、コーディネーターを務める。また、ショールームのディスプレイ設営も担当。
              </p>
              <p>
                独立後、不動産の空室物件販売促進のためのホームステージング事業のホームステージャーとして活動。
              </p>
              <p>
                同時に、スペインの家具ブランドの日本総代理店　"LaForma
                Japan"の立ち上げメンバーとして輸入手続き、在庫管理、ショールーム設営、お客様宅コーディネートまで一通りを担当する。
              </p>
              <p>
                HBA Tokyo Office
                にてヒルトンホテルなどの大手外資ラグジュアリーホテルのデザインを手がける。
              </p>
              <p>
                デザインのインスピレーションを得るため、NYとコペンハーゲンに１年ずつ在住経験あり。
                <br />
                ​今まで２０ヶ国以上を訪れ様々なものを見てきた経験もあり、最大限豊かな空間にするアイデア提供​を得意とする。
              </p>

              <p>
                ＜現在の主な活動内容＞
                <br />
                サービスアパートメント、ホテル、個人邸、店鋪等のインテリアデザイン
                <br />
                住宅展示場でのセミナー講師
                <br />
                ​オンラインデザイン事業委託業務
                <br />
                テレビ東京「インテリア日和」出演　など
              </p>
              <p>
                ＜略歴＞
                <br />
                日本大学　建築・地域共生デザイン研究室卒業
                <br />
                IKEA　ソファ・リビング収納コーナー　セールス
                <br />
                町田ひろ子アカデミー全日制卒業
                <br />
                Arflex Japan 広尾ショップ　インテリアコーディネーター
                <br />
                キナリノ記事ライター
                <br />
                Home Staging Japan ホームステージャー
                <br />
                Laforma Japan　日本ブランド立ち上げ
                <br />
                HBA　インテリアデザイナー　など
              </p>
              <p>
                ＜渡航歴＞
                <br />
                渡航経験２3ヶ国以上
                <br />
                ホームステイ経験：韓国・中国・アメリカコネチカット州
                <br />
                在住経験：ニューヨーク約１年・デンマーク約１年
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
