import React, { useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";

function Main() {
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const contactRef = useRef(null);

  const sendContact = () => {
    const newErrors = {};
    const cardInputs = document.querySelectorAll(
      'input[name="name"], input[name="tel"], input[name="email"],textarea[name="message"]'
    );
    cardInputs.forEach((input) => {
      if (input.tagName === "TEXTAREA" || input.tagName === "INPUT") {
        if (!input.value) {
          newErrors[input.name] = "该项目必须填写";
          input.style.border = "2px solid red";
        } else {
          input.style.border = "";
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      alert("すべての情報を入力してください。");
    } else {
      alert("送信成功!");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <img
            alt=""
            src="https://static.wixstatic.com/media/149b90_dc35f445c84042ca96eb15dbfeb52eed~mv2.png/v1/fill/w_1897,h_843,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/149b90_dc35f445c84042ca96eb15dbfeb52eed~mv2.png"
          />
          <div className="block-mv04_txt">
            <h2
              className="font_2 wixui-rich-text__text"
              style={{ fontSize: "33px" }}
            >
              <h2
                style={{
                  fontFamily: "kepler-w03-light-scd-cp, serif",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: "#FFFFFF",
                  fontSize: "33px",
                }}
              >
                -PORTFOLIO-
              </h2>
              <h2
                style={{
                  color: "#FFFFFF",
                  fontFamily: "kepler-w03-light-scd-cp, serif",
                  textShadow: "rgba(0, 0, 0, 0.4) 0px 4px 5px",
                  fontWeight: "bold",
                  fontSize: "33px",
                }}
              >
                住居、ホームステージング、オフィス、カフェ、ショップなどの納品事例をご覧いただけます。
              </h2>
            </h2>
          </div>
        </div>

        <section
          id="comp-lcirjleq1"
          tabIndex="-1"
          className="Oqnisf comp-lcirjleq1 wixui-section"
          data-block-level-container="ClassicSection"
        >
          <div
            data-mesh-id="comp-lcirjleq1inlineContent"
            data-testid="inline-content"
          >
            <div
              data-mesh-id="comp-lcirjleq1inlineContent-gridContainer"
              data-testid="mesh-container-content"
            >
              <div id="comp-k2vlnfgr" className="SsY_2i comp-k2vlnfgr">
                <div className="UGHfip wixui-vertical-line"></div>
              </div>
              <div
                id="comp-k2vl51id"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-k2vl51id wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    .01&nbsp;
                  </span>
                </h2>
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    SERVICE
                  </span>
                </h2>
              </div>
              <div
                id="comp-ku83yohq"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-ku83yohq wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  基本的な流れ
                </h2>
              </div>
              <div className="first_three_box">
                <div className="first_three_box_grid">
                  <div className="grid__inner">
                    <img alt="" src="/head/hear.png" />
                    <h2>HEARING</h2>
                    <p>
                      まずは、お困りのことややりたいことを図面や写真と共にヒアリングさせていただきます。新しい素敵なお店をオープンしたいけど、素敵にってどう表現したら良いかわからない、お部屋を素敵にしたいけどイメージをどうやって伝えたら良いかわからない、そんな場合もコンセプトワークからお手伝いします。
                    </p>
                  </div>
                  <div className="grid__inner">
                    <img alt="" src="/head/plan.png" />
                    <h2>Design Planning</h2>
                    <p>
                      部屋の雰囲気を変えたい、新築でインテリアの相談をしたい、予算がないから少しだけ手を加えたいなど、色々なケースに対応させていただいております。
                      ​オンラインだけでアドバイスをお伝えするプランから納品やリノベーションを含めたものまでご要望に合わせて幅広く対応可能です。
                    </p>
                  </div>
                  <div className="grid__inner">
                    <img alt="" src="/head/set.png" />
                    <h2>setting & Decoration</h2>
                    <p>
                      setting & Decoration
                      最後の仕上げであるデコレーションは空間・インテリアの良さを最大限引き出す鍵です。ショップのデコレーションから住宅の納品、飾り付けまで承っております。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="comp-lcirjleq1"
          tabIndex="-1"
          className="Oqnisf comp-lcirjleq1 wixui-section"
          data-block-level-container="ClassicSection"
        >
          <div
            data-mesh-id="comp-lcirjleq1inlineContent"
            data-testid="inline-content"
          >
            <div
              data-mesh-id="comp-lcirjleq1inlineContent-gridContainer"
              data-testid="mesh-container-content"
            >
              <div id="comp-k2vlnfgr" className="SsY_2i comp-k2vlnfgr">
                <div className="UGHfip wixui-vertical-line"></div>
              </div>
              <div
                id="comp-k2vl51id"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-k2vl51id wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    .02&nbsp;
                  </span>
                </h2>
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    PORTFOLIO
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="many_img">
            <div>
              <img alt="" src="/main/1001.png" />
              <img alt="" src="/main/1005.png" />
            </div>
            <div>
              <img alt="" src="/main/1002.png" />
              <img alt="" src="/main/1006.png" />
            </div>
            <div>
              <img alt="" src="/main/1003.webp" />
              <img alt="" src="/main/1007.png" />
            </div>
            <div>
              <img alt="" src="/main/1004.webp" />
              <img alt="" src="/main/1008.webp" />
            </div>
          </div>
        </section>

        <section
          id="comp-lcirjleq1"
          tabIndex="-1"
          className="Oqnisf comp-lcirjleq1 wixui-section"
          data-block-level-container="ClassicSection"
        >
          <div
            data-mesh-id="comp-lcirjleq1inlineContent"
            data-testid="inline-content"
          >
            <div
              data-mesh-id="comp-lcirjleq1inlineContent-gridContainer"
              data-testid="mesh-container-content"
            >
              <div id="comp-k2vlnfgr" className="SsY_2i comp-k2vlnfgr">
                <div className="UGHfip wixui-vertical-line"></div>
              </div>
              <div
                id="comp-k2vl51id"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-k2vl51id wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    .03&nbsp;
                  </span>
                </h2>
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    ABOUT YUMI
                  </span>
                </h2>
              </div>
              <div
                id="comp-ku83yohq"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-ku83yohq wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "rgb(96,96,96)",
                    fontStyle: "italic",
                  }}
                >
                  Design philosophy - keep doing the best and be bold
                </h2>
              </div>
              <div className="first_three_box">
                <div className="first_three_box_grid1">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/149b90_bc24092be5034353bebaa7329e1951c9~mv2.jpg/v1/crop/x_236,y_0,w_479,h_607/fill/w_359,h_445,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_7706_JPG.jpg"
                  />
                  <div>
                    <p>宿谷 悠美（しゅくや　ゆみ）</p>
                    <p>
                      ２０１５年 Umyco Design　として独立。
                      <br />
                      以来、住宅から商業施設まで幅広い範囲を対象にフリーランスのインテリアデザイナーとして活動中。
                    </p>
                    <p>テレビ東京「インテリア日和」出演。</p>
                    <p>
                      大学で建築を学び、いくつかの家具ブランドやインテリア事務所などでの勤務を経て今に至る。
                    </p>
                    <p>
                      デザインのインスピレーションを得るため、NYとコペンハーゲンに１年ずつ在住経験あり。
                    </p>
                    <p>
                      今まで２０ヶ国以上を訪れ様々なものを見てきた経験もあり、最大限豊かな空間にするアイデア提供​を得意とする。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="comp-lcirjleq1"
          tabIndex="-1"
          className="Oqnisf comp-lcirjleq1 wixui-section"
          data-block-level-container="ClassicSection"
        >
          <div
            data-mesh-id="comp-lcirjleq1inlineContent"
            data-testid="inline-content"
          >
            <div
              data-mesh-id="comp-lcirjleq1inlineContent-gridContainer"
              data-testid="mesh-container-content"
            >
              <div id="comp-k2vlnfgr" className="SsY_2i comp-k2vlnfgr">
                <div className="UGHfip wixui-vertical-line"></div>
              </div>
              <div
                id="comp-k2vl51id"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-k2vl51id wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    .04&nbsp;
                  </span>
                </h2>
              </div>
              <div
                id="comp-ku83yohq"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-ku83yohq wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "rgb(96,96,96)",
                    fontStyle: "italic",
                  }}
                >
                  Follow Youtube to see newest feed
                </h2>
              </div>
              <div className="first_three_box">
                <div className="first_three_box_grid1">
                  <iframe
                    width="704"
                    height="396"
                    src="https://www.youtube.com/embed/Kxr51hosIyg"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="comp-lcirjleq1"
          tabIndex="-1"
          className="Oqnisf comp-lcirjleq1 wixui-section"
          data-block-level-container="ClassicSection"
          ref={contactRef}
        >
          <div
            data-mesh-id="comp-lcirjleq1inlineContent"
            data-testid="inline-content"
          >
            <div
              data-mesh-id="comp-lcirjleq1inlineContent-gridContainer"
              data-testid="mesh-container-content"
            >
              <div id="comp-k2vlnfgr" className="SsY_2i comp-k2vlnfgr">
                <div className="UGHfip wixui-vertical-line"></div>
              </div>
              <div
                id="comp-k2vl51id"
                className="HcOXKn SxM0TO QxJLC3 lq2cno comp-k2vl51id wixui-rich-text"
                data-testid="richTextElement"
              >
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    .05&nbsp;
                  </span>
                </h2>
                <h2
                  className="font_2 wixui-rich-text__text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  <span
                    style={{ letterSpacing: "0.1em", fontStyle: "italic" }}
                    className="wixui-rich-text__text"
                  >
                    CONTACT
                  </span>
                </h2>
              </div>

              <div className="first_three_box">
                <div className="first_three_box_grid2">
                  <h6>お問合せ</h6>
                  <div>
                    <input
                      name="name"
                      id="input_comp-k32gcdae"
                      class="KvoMHf has-custom-focus wixui-text-input__input"
                      type="text"
                      placeholder="名前"
                      aria-required="false"
                      aria-invalid="false"
                      maxlength="100"
                      autocomplete="off"
                      aria-label="名前"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      name="tel"
                      id="input_comp-k32gcdhe"
                      class="KvoMHf has-custom-focus wixui-text-input__input"
                      type="tel"
                      placeholder="電話番号"
                      aria-required="false"
                      aria-invalid="false"
                      maxlength="50"
                      autocomplete="off"
                      aria-label="電話番号"
                      value={tel}
                      onChange={(e) => setTel(e.target.value)}
                    />
                    <input
                      name="email"
                      id="input_comp-k32gcdes"
                      class="KvoMHf has-custom-focus wixui-text-input__input"
                      type="email"
                      placeholder="メールアドレス"
                      required=""
                      aria-required="true"
                      aria-invalid="true"
                      pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                      maxlength="250"
                      autocomplete="off"
                      aria-label="メールアドレス"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <textarea
                      id="textarea_comp-k32gcdjr"
                      class="rEindN has-custom-focus wixui-text-box__input"
                      placeholder="メッセージを入力"
                      aria-required="false"
                      aria-invalid="false"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="first_btn">
                    <button onClick={sendContact}>送信する</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer contactRef={contactRef} />
    </div>
  );
}

export default Main;
