const productData = [
  {
    id: 1,
    name: "A.　1部屋のインテリアコーディネート(低画質） ",
    details: [
      "・テキスト形式での家具のご提案、解説（家具URL付）",
      "・お部屋を再現した3Dイメージパース（低画質）",
    ],
    linkPdf: "/head/低画質PDF.pdf",
    price: "33000",
  },
  {
    id: 2,
    name: "B.　1部屋のインテリアコーディネート(高画質） ",
    details: [
      "・テキスト形式での家具のご提案、解説（家具URL付）",
      "・お部屋を再現した3Dイメージパース（高画質）",
    ],
    linkPdf: "/public/head/6枚の-pawapo.pdf",
    price: "55000",
  },
];

export default productData;
