import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/other.css";

function Other() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div
          className="first_three_box"
          style={{ paddingTop: "3%", paddingBottom: "6%" }}
        >
          <div>
            <div>
              <h1 className="first_three_box_grid_other">Others</h1>
              <div
                id="comp-ku7yagh4"
                className="comp-ku7yagh4 aVng1S wixui-horizontal-line"
              ></div>
            </div>
            <div className="other_imgs">
              <div className="imgs_inner">
                <div>
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/a3c153_a0c884d86e064a5ba8e7cc5f990e2f8e~mv2.jpg/v1/fill/w_569,h_431,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/11062b_f0cd2b56e86443d68d21b6bc12fe055c_.jpg"
                  />
                  <p>Youtube</p>
                </div>
              </div>
              <div className="imgs_inner">
                <div>
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/a3c153_608f616240074eec8eb852372ce3f0a6~mv2.jpg/v1/fill/w_569,h_431,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/11062b_fd5c21cdc57f4b19b00f8ec0988396fe_.jpg"
                  />
                  <p>Instagram</p>
                </div>
              </div>

              <div className="imgs_inner">
                <div>
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/a3c153_b5136094eb074f78a684639aeac9b2f1~mv2.jpg/v1/fill/w_569,h_431,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/noah-boyer-mXz8dKrpO8w-unsplash.jpg"
                  />
                  <p>Blog</p>
                </div>
              </div>
              <div className="imgs_inner">
                <div>
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/a3c153_29ef14be24914348a8b5ad2b25f7ce2b~mv2.jpg/v1/fill/w_569,h_431,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/sumner-mahaffey-7Y0NshQLohk-unsplash.jpg"
                  />
                  <p>Medias</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Other;
