import "./App.css";
import Main from "./Component/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CartProvider } from "./Component/CartContext";
import Service from "./Component/Service";
import About from "./Component/About";
import Other from "./Component/Other";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/service" element={<Service />} />
          <Route path="/about" element={<About />} />
          <Route path="/other" element={<Other />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
