import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={`header-container ${scrolled ? "scrolled" : ""}`}>
      <div className="header">
        <div className="header__inner__left">
          <p>UMYCO DESIGN</p>
        </div>
        <div className="header__inner">
          <div className="wrp-navi">
            <Link to="/" onClick={toTop}>
              HOME
            </Link>
            <Link to="/service" onClick={toTop}>
              SERVICE
            </Link>
            <Link to="/about" onClick={toTop}>
              ABOUT
            </Link>
            <Link to="/other" onClick={toTop}>
              OTHER
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
