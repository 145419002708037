import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/service.css";

function Service() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox" style={{ paddingLeft: "28%" }}>
        <div className="service_div">
          <h1>Services</h1>
          <p>
            オンライン完結で簡単にプロに提案をお願いしたという場合には、オンラインインテリアデザインサービスをお選びください。
            <br />
            その他現地での細かな打ち合わせや納品・家具のセッティング等までご希望の場合はインテリアプランのサービスにてご依頼ください。
            <br />
            詳しくはそれぞれのページでご確認ください。
          </p>
        </div>
      </div>
      <div className="three_div_box">
        <div className="three_div">
          <img alt="" src="/main/1009.webp" />
          <img alt="" src="/main/10010.webp" />
          <img alt="" src="/main/10011.webp" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Service;
